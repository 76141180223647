body {
    font-family: 'Poppins', sans-serif;
}

a {
    color: #ED7B0B;
    text-decoration: none;
}

h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: 'Libre Baskerville', 'Poppins', serif;
    color: #000000;
    opacity: 0.8;
}

h2::before {
    background-image: url(../images/design-3.svg);
    background-position: top center;
    background-repeat: no-repeat;
    display: block;
    width: -webkit-fill-available;
    height: 57px;
    content: "";
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.menuicon svg {
    color: #ED7B0B;
}

.section-bg {
    background-color: #FFECD8;
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scaleX(-1);
}

.btn-primary {
    padding: 12px 28px 12px 28px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #ED7B0B;
    border-color: #ED7B0B;
}

.btn-primary:hover {
    color: #ED7B0B;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}

.MuiPaper-root a {
    color: #000;
}

/* Header */

#header .container {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

/* END: Header */

/* Navbar */

.navbar ul {
    margin-top: 1rem;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 40px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    /* opacity: 0.8; */
}

/* END: Navbar */

#intro {
    padding-top: 200px;
    padding-bottom: 182px;
    min-height: 100%;
    background: linear-gradient( 111deg, rgba(0, 0, 0, 33%), rgba(0, 0, 0, 33%)), url(../images/premium-quality-dry-fruit-wholesaler.png);
    background-size: cover;
    background-position: top center;
}

#intro {
    text-align: center;
    color: #FFFFFF;
}

#intro h1 {
    font-size: 44px;
    font-weight: 600;
    font-family: 'Libre Baskerville', 'Poppins', serif;
    text-transform: capitalize;
}

#intro p {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 16px;
    font-weight: 400;
}

#about, #products {
    text-align: center;
}

#about p, #products p {
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    color: #000000;
    opacity: 0.7;
}

.design-1 {
    overflow: auto;
    margin-top: -12%;
}

section.design-2 {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #312D46;
}

.design-2 h2 {
    text-align: center;
    color: #FFFFFF;
    margin-bottom: unset;
    opacity: 1;
}

.design-4 {
    margin-top: -18%;
}

.design-2 h2::after {
    content: "";
    margin-top: 10px;
    height: 50px;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url(../images/design-3.svg);
    width: -webkit-fill-available;
    display: block;
}

.design-7 {
    float: right;
    margin-top: -3%;
}

.design-2 h2::before {
    margin-bottom: 10px;
}

.product {
    margin-top: 3rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #F9F9FD;
    transition: all .5s;
}

.product:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 30px #3578C629;
    border-radius: 9px;
}

.product img:hover {
    cursor: zoom-in;
}

.product .info {
    padding: 25px;
}

.product h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.product p {
    margin-top: 1rem;
    font-size: 14px !important;
    line-height: unset !important;
    font-weight: 400;
    color: #000000;
    margin-bottom: unset;
    opacity: 0.8;
}

#contact {
    text-align: center;
    margin-top: 5rem;
    background-image: url(../images/design-6.png);
    background-repeat: no-repeat;
    background-position: left bottom;
}

#contact svg {
    border-radius: 50%;
    padding: 16px;
    color: #ED7B0B;
    border: 1px solid #ED7B0B;
    font-size: 68px;
    margin-bottom: 1rem;
}

#contact h2 {
    opacity: 1;
}

#contact h3 {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    opacity: 0.85;
}

#contact a, #contact p {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: 0.85;
    margin-bottom: unset;
}

#contact a:hover {
    color: #ED7B0B;
}

#contact .details {
    padding: 20px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 30px #3578C629;
    border-radius: 9px;
    transition: all .5s;
}

#contact .details:hover {
    box-shadow: unset;
    background-color: #F9F9FD;
}

#footer {
    background-color: #312D46;
}

#footer section {
    padding-top: 68px;
    padding-bottom: 10px;
}

#footer hr {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: #FFFFFF;
}

#footer ul {
    margin-top: 2rem;
    padding-left: unset;
    list-style: none;
}

#footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

#footer h5 {
    font-size: 16px;
    font-weight: 600;
    color: #FFD9B4;
    text-transform: uppercase;
}

#footer h5:after {
    display: block;
    position: absolute;
    width: 2rem;
    height: 4px;
    content: "";
    background: #FFD9B4;
    border-radius: 10px;
    margin-top: 5px;
    border: 1px solid #ffc942;
}

#footer h6 a {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

#footer h6 a:hover {
    color: #ED7B0B;
}

.copyright {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.copyright p, .copyright a {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FCFCFC;
    opacity: 0.8;
}

.copyright a:hover {
    color: #ED7B0B;
}