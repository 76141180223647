@media (max-width: 768px) {
    #header {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    #intro {
        padding-top: 42px;
        padding-bottom: 50px;
    }
    #intro h1 {
        font-size: 26px;
    }
    .btn-primary {
        padding: 10px 14px 10px 14px;
        font-size: 14px;
    }
    h2 {
        font-size: 20px;
        margin-bottom: 18px;
    }
    h2::before {
        margin-bottom: 10px;
    }
    #about p {
        font-size: 16px;
        line-height: 32px;
    }
    #contact {
        margin-top: 2rem;
    }
    section {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .design-1 img {
        width: 15%;
    }
    .design-4 {
        width: 30%;
        margin-top: -4%;
    }
    .design-5 {
        width: 25%;
    }
    .product {
        margin-top: 1rem;
    }
    .product h3 {
        font-size: 16px;
    }
    #contact .details {
        margin-top: 2rem;
    }
    .copyright p, .copyright a {
        font-size: 14px;
    }
}